import React from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import './SuccessComp.css'

const SuccessComp = () => {
    return (
        <div className='SuccessComp-body'>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    mx: 'auto',
                    maxWidth: '327px'
                }}
            >

                <Box
                    sx={{
                        p: 2,
                        maxWidth: 375,
                        maxHeight: 618,
                        width: '100%',
                        mt: 4,
                    }}
                >
                    <div style={{ backgroundColor: 'white', maxWidth: '295px', borderRadius: '7px', padding: '15px' }}>
                        <span className='SuccessComp-text-box'>Congratulations! You took the test</span><br />
                        <span className='SuccessComp-text-box-desc'>You can see the test results in profile overview soon</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                     <img src={`${process.env.PUBLIC_URL}/WomanRunning.svg`} alt="Woman running"  />
                    </div>
                    <div style={{ width: '100%', height: '100px', paddingTop: '40px' }}>
                        <Button className='okay-btn' variant="outlined" style={{width: '100%', border: '1px solid black', color: 'black'}}>Okay</Button>
                    </div>
                </Box>
            </Box>
        </div>
    );
}

export default SuccessComp;