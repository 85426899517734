import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import Quiz from './components/Quiz'
import Alert from './components/Alert'
import SuccessComp from './components/SuccessComp'
import SkeletonComp from './components/SkeletonComp'

function App() {
  const location = useLocation();
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const token = location.hash.substring(2);

  useEffect(() => {
    fetch(process.env.REACT_APP_VALIDATE_TOKEN_API ?? '', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ accessToken: token }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setError(data.error);
        } else {
          setIsValid(data.isValid);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [token]);

  return (
    <div>
      <Box sx={{ position: 'fixed', background: '#fffefe', width: '100%' }}>
        <img src={`${process.env.PUBLIC_URL}/ccapLogo.svg`} alt="logo" style={{ height: '27px', padding: '20px' }} />
      </Box>
      {isValid === null && error === null && <SkeletonComp />}
      {isValid === true && !isSubmitted && <Quiz setIsSubmitted={setIsSubmitted} />}
      {isValid === false && error !== "Parent already submitted test" && <Alert />}
      {error === "Parent already submitted test" && <Alert />}
      {isSubmitted && <SuccessComp />}
    </div>
  );
}

export default App;