import React from 'react';
import { Box } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import './SkeletonComp.css'

const SkeletonComp = () => {
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    mx: 'auto',
                    maxWidth: '327px'
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                    <Skeleton style={{ width: '40%' }} />
                </Box>
                <Skeleton variant="rectangular" height={30} style={{width: '100%'}}/>
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
                <Skeleton style={{width: '100%'}} />
            </Box>
        </div>
    );
}

export default SkeletonComp;