import React, { useState, useEffect } from 'react';
import { Button, LinearProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import questions from './questions.json';
import { Box } from '@mui/system';
import './Quiz.css';

const totalQuestions = questions.length;

interface QuizProps {
    setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const Quiz: React.FC<QuizProps> = ({ setIsSubmitted }) => {
    const location = useLocation();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOption, setSelectedOption] = useState<number | null>(null);
    const [answers, setAnswers] = useState<{ questionId: string, answer: string }[]>([]);
    const [isTestFinished, setIsTestFinished] = useState(false);
    const token = location.hash.substring(2);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(Number(event.target.value));
    };

    const handleNext = () => {
        if (selectedOption === null) {
            alert('Please select an option');
            return;
        }

        // Save the answer
        setAnswers(prevAnswers => [
            ...prevAnswers,
            { questionId: questions[currentQuestion].id, answer: questions[currentQuestion].options[selectedOption] }
        ]);

        if (currentQuestion < totalQuestions - 1) {
            setCurrentQuestion(currentQuestion + 1);
            setSelectedOption(null);
        } else {
            // Test finished
            setIsTestFinished(true);
        }
    };

    useEffect(() => {
        if (isTestFinished) {
            // Send answers to API
            fetch(process.env.REACT_APP_SUBMIT_API ?? '', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ accessToken: token, answers }),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setIsSubmitted(true);
                        alert('Test submitted successfully!');
                    } else {
                        alert('Error: ' + data.error);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [isTestFinished, answers, token, setIsSubmitted]);

    const progress = ((currentQuestion + 1) / totalQuestions) * 100;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                mx: 2,
                backgroundColor: '#fffefe',
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    p: 2,
                    maxWidth: 375,
                    width: '100%',
                    mt: 2,
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 160px)',
                }}
            >
                <div className='neuroColor'>NeuroColor</div>
                <span className='questions'>{questions[currentQuestion].text}</span>
                <div>
                    {questions[currentQuestion].options.map((option, index) => (
                        <div className="option" key={index}>
                            <div>{option}</div>
                            <input
                                type="radio"
                                name={`question-${questions[currentQuestion].id}`}
                                value={index}
                                checked={selectedOption === index}
                                onChange={handleOptionChange}
                            />
                        </div>
                    ))}
                </div>
            </Box>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#fffefe',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        width: '100%',
                        bgcolor: 'grey.500',
                        '& .MuiLinearProgress-bar': {
                            bgcolor: '#323232',
                        }
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        sx={{ bgcolor: '#DC0079', my: 2 }}
                    >
                        {currentQuestion === totalQuestions - 1 ? 'Finish Test' : 'Next'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Quiz;